// Fonts
$fz-root: 20px;
$fz-root-m: 16px;
$ff-main: 'Montserrat', sans-serif;
$letter-spacing: (0.3 / 20) + em;

// Colors
$c-white: #ffffff;
$c-black: #19191B;
$c-link-def: #C2C3C5;
$c-link-def-darken: #848484;
$c-text: #ffffff;

// Timings
$time-fast: .3s;
$time-normal: .5s;

$timing-functions: (
  'easeInOutCubic': cubic-bezier(0.645, 0.045, 0.355, 1)
);

$header-h: 102px;
$header-m-h: 45px;
$header-m-h-big: 74px;
$padding-hor: 60px;
$padding-hor-m: 16px;

$vh-key: --vh !default;
$vh-key-init: --vh-init !default;

$vh1: var(#{$vh-key}, 1vh) !default;
$vh1init: var(#{$vh-key-init}, 1vh) !default;
$vh100: calc(var(#{$vh-key}, 1vh) * 100) !default;
$vh100init: calc(var(#{$vh-key-init}, 1vh) * 100) !default;

$m-panel-h: 70px;

// to kill
$sidebar-w: 0px;

// z-index

$z-over: 10;
$z-alpha-over: 1000;
$z-mega-over: 10000;
