@function i($name) {
  @return url('../i/#{$name}');
}

@function rem($root, $current: $fz-root) {
  @return ($current / $root) * 1rem;
}

@function svg($name) {
  @return url('../i/#{$name}.svg');
}

// Converts pixels to em
@function em($src-val-pixels, $relative-val: $fz-root, $root-font-size-px: $fz-root) {
  // benefits of this function:
  // * no need type px
  // * can do em(270/2), em(270*2), em(270/2+5), em(270/2-5)

  // usage examples:
  // em(16)
  // em(24, 16)

  // for simple transition from px units to non px units
  @if str-length(unit($src-val-pixels)) == 0 {
    $src-val-pixels: $src-val-pixels * 1px;
  }
  @if str-length(unit($relative-val)) == 0 {
    $relative-val: $relative-val * 1px;
  }

  @if unit($relative-val) == 'em' {
    $relative-val: $relative-val / ($relative-val * 0 + 1); // strip units
    @return #{($src-val-pixels / ($root-font-size-px * $relative-val)) * 1em};
  } @else if unit($relative-val) == 'px' {
    @return #{$src-val-pixels / $relative-val * 1em};
  }
}

// Converts pixels to rem
@function rem($src-val-pixels, $relative-val: $fz-root, $root-font-size-px: $fz-root) {
  // benefits of this function:
  // * no need type px
  // * can do em(270/2), em(270*2), em(270/2+5), em(270/2-5)

  // usage examples:
  // em(16)
  // em(24, 16)

  // for simple transition from px units to non px units
  @if str-length(unit($src-val-pixels)) == 0 {
    $src-val-pixels: $src-val-pixels * 1px;
  }
  @if str-length(unit($relative-val)) == 0 {
    $relative-val: $relative-val * 1px;
  }

  @if unit($relative-val) == 'rem' {
    $relative-val: $relative-val / ($relative-val * 0 + 1); // strip units
    @return #{($src-val-pixels / ($root-font-size-px * $relative-val)) * 1rem};
  } @else if unit($relative-val) == 'px' {
    @return #{$src-val-pixels / $relative-val * 1rem};
  }
}
