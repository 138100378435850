.content-area {
  font-size: 1rem;
  font-weight: 300;

  @include mmax(768) {
    font-size: rem(12);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 em(15) 0;

    font-weight: 500;
  }

  h1 {

  }

  h2 {
    font-size: em(25);
  }

  h3 {
    font-size: em(21);
  }

  h4 {
    font-size: em(18);
  }

  h5 {
    font-size: em(16);
  }

  h6 {
    font-size: em(14);
  }

  p, div {
    margin: 0;
    &:not(:last-child) {
      margin-bottom: em(15);
    }
  }

  blockquote {
    font-style: italic;
  }

  ul,
  ol {
    padding-left: em(30);
    margin-bottom: em(15);
  }

  ul {

  }

  ol {

  }

  li {

  }

  ul li {
    list-style-type: disc;
  }

  ol li {
    list-style-type: decimal;
  }

  a {
    @include link {
      font-family: inherit;
      text-decoration: underline;
    }

    @include link-h {
      text-decoration: none;
    }
  }

  b,
  strong {
    font-family: inherit;
    font-weight: 500;
  }

  i,
  em {
    font-style: italic;
  }

  img {
    float: none !important;

    display: block;
    margin: em(15) 0;

    max-width: 100%;
    width: 100%;
    height: auto !important;
  }
}
