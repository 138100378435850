.react-select {
  width: 100%;

  font-size: 1rem;

  @include mmax(768) {
    width: 100%;

    font-size: rem(14);
  }

  &__menu {
    z-index: 1;
  }

  & &__control {
    padding: 0 rem(42);
    border: 1px solid rgba($c-white, 0.2);

    height: rem(66);

    border-radius: 0;
    background: transparent;
    transition: all $time-fast;
    box-shadow: none;
    cursor: pointer;

    @include mmax(768) {
      border: none;
      padding: 0;

      height: rem(50);
    }

    &--is-focused {
      border-color: rgba($c-white, 0.5);
    }

    &:hover {
      border-color: rgba($c-white, 0.5);
    }
  }

  & &__value-container {
    padding: 0;

    color: $c-white;
  }

  & &__single-value {
    margin: 0;

    color: inherit;
  }

  &__indicator-separator {
    display: none;
  }

  & &__dropdown-indicator {
    padding: 0;

    svg {
      display: none;
    }

    &:after {
      content: '';

      display: block;
      @include size(25, 8);

      background: url("../../images/arrow-down.svg") center center no-repeat;
      background-size: contain;
    }
  }

  & &__menu {
    margin: 0;
    border: 1px solid rgba($c-white, 0.2);
    border-top: 0;

    border-radius: 0;
    background: $c-black;
    box-shadow: none;

    @include mmax(768) {
      border: 1px solid rgba($c-white, 0.2);
    }
  }

  & &__menu-list {
    padding: 0;
  }

  & &__option {
    padding: rem(10) rem(42);

    transition: all $time-fast;
    cursor: pointer;

    @include mmax(768) {
      padding: rem(10) rem(15);
    }

    &--is-selected {
      background: rgba($c-white, 0.1);
    }

    &--is-focused {
      background: rgba($c-white, 0.05);
    }
  }
}
