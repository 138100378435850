@function i($name) {
  @return url('../i/#{$name}');
}

@function rem($root, $current: $fz-root) {
  @return ($current / $root) * 1rem;
}

@function svg($name) {
  @return url('../i/#{$name}.svg');
}

// Converts pixels to em
@function em($src-val-pixels, $relative-val: $fz-root, $root-font-size-px: $fz-root) {
  // benefits of this function:
  // * no need type px
  // * can do em(270/2), em(270*2), em(270/2+5), em(270/2-5)

  // usage examples:
  // em(16)
  // em(24, 16)

  // for simple transition from px units to non px units
  @if str-length(unit($src-val-pixels)) == 0 {
    $src-val-pixels: $src-val-pixels * 1px;
  }
  @if str-length(unit($relative-val)) == 0 {
    $relative-val: $relative-val * 1px;
  }

  @if unit($relative-val) == 'em' {
    $relative-val: $relative-val / ($relative-val * 0 + 1); // strip units
    @return #{($src-val-pixels / ($root-font-size-px * $relative-val)) * 1em};
  } @else if unit($relative-val) == 'px' {
    @return #{$src-val-pixels / $relative-val * 1em};
  }
}

// Converts pixels to rem
@function rem($src-val-pixels, $relative-val: $fz-root, $root-font-size-px: $fz-root) {
  // benefits of this function:
  // * no need type px
  // * can do em(270/2), em(270*2), em(270/2+5), em(270/2-5)

  // usage examples:
  // em(16)
  // em(24, 16)

  // for simple transition from px units to non px units
  @if str-length(unit($src-val-pixels)) == 0 {
    $src-val-pixels: $src-val-pixels * 1px;
  }
  @if str-length(unit($relative-val)) == 0 {
    $relative-val: $relative-val * 1px;
  }

  @if unit($relative-val) == 'rem' {
    $relative-val: $relative-val / ($relative-val * 0 + 1); // strip units
    @return #{($src-val-pixels / ($root-font-size-px * $relative-val)) * 1rem};
  } @else if unit($relative-val) == 'px' {
    @return #{$src-val-pixels / $relative-val * 1rem};
  }
}

// Fonts
$fz-root: 20px;
$fz-root-m: 16px;
$ff-main: 'Montserrat', sans-serif;
$letter-spacing: (0.3 / 20) + em;

// Colors
$c-white: #ffffff;
$c-black: #19191B;
$c-link-def: #C2C3C5;
$c-link-def-darken: #848484;
$c-text: #ffffff;

// Timings
$time-fast: .3s;
$time-normal: .5s;

$timing-functions: (
  'easeInOutCubic': cubic-bezier(0.645, 0.045, 0.355, 1)
);

$header-h: 102px;
$header-m-h: 45px;
$header-m-h-big: 74px;
$padding-hor: 60px;
$padding-hor-m: 16px;

$vh-key: --vh !default;
$vh-key-init: --vh-init !default;

$vh1: var(#{$vh-key}, 1vh) !default;
$vh1init: var(#{$vh-key-init}, 1vh) !default;
$vh100: calc(var(#{$vh-key}, 1vh) * 100) !default;
$vh100init: calc(var(#{$vh-key-init}, 1vh) * 100) !default;

$m-panel-h: 70px;

// to kill
$sidebar-w: 0px;

// z-index

$z-over: 10;
$z-alpha-over: 1000;
$z-mega-over: 10000;

@mixin ib {
  display: inline-block;
  vertical-align: top;
}

@mixin it {
  display: inline-table;
  vertical-align: middle;
}

@mixin link {
  &,
  &:hover,
  &:active,
  &:visited,
  &:focus {
    @content;
  }
}

@mixin link-h {
  &:hover,
  &:active,
  &:focus {
    @content
  }
}

@mixin link-v {
  &,
  &:visited {
    @content;
  }
}

@mixin mmax( $width, $height: 0 ) {
  $w: $width + px;
  $h: $height + px;

  @if $height == 0 {
    @media all and (max-width: $w) {
      @content;
    }
  } @else {
    @media all and (max-width: $w) and (max-height: $h) {
      @content;
    }
  }
}

@mixin mmin( $width, $height: 0 ) {
  $w: $width + px;
  $h: $height + px;

  @if $height == 0 {
    @media all and (min-width: $w) {
      @content;
    }
  } @else {
    @media all and (min-width: $w) and (min-height: $h) {
      @content;
    }
  }
}

@mixin mmax-h( $height ) {
  $h: $height + px;

  @media all and (max-height: $h) {
    @content;
  }
}

@mixin mmax-vert( $width, $height: 1024 ) {
  $w: $width + px;
  $h: $height + px;
  @media all and (max-width: $w) and (max-height: $h) {
    @content;
  }
}

@mixin mmax-landscape( $width, $height: 0 ) {
  $w: $width + px;
  $h: $height + px;

  @if $h = 0 {
    @media all and (max-width: $w) and (orientation: landscape){
      @content;
    }
  } @else {
    @media all and (max-width: $w) and (max-height: $h) and (orientation: landscape) {
      @content;
    }
  }
}

@mixin adapt-img {
  max-width: 100%;
  height: auto;
}

@mixin placeholder {
  &::-webkit-input-placeholder {@content;}
  &:-ms-input-placeholder {@content;}
  &::-ms-input-placeholder {@content;}
  &:-moz-placeholder {@content;}
  &::-moz-placeholder {@content;}
}

@mixin placeholder-a-left {
  @include placeholder {
    @content;
    opacity: 1;
    text-indent: 0px;
    transition: .3s all;
  }

  &:focus {
    @include placeholder {
      opacity: 0;
      text-indent: -50px;
    }
  }
}

@mixin placeholder-a-fade {
  @include placeholder {
    @content;
    opacity: 1;
    transition: .3s all;
  }

  &:focus {
    @include placeholder {
      opacity: 0;
    }
  }
}

@mixin no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

@mixin select {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
}


@mixin triangle( $color, $size ) {
  @include ib;

  border: $size / 2 + px solid transparent;
  border-top-color: $color;
  border-bottom: 0;

  width: $size + px;
  height: $size + px;
}

@mixin triangle-rev( $color, $size ) {
  @include ib;

  border: $size / 2 + px solid transparent;
  border-bottom-color: $color;
  border-top: 0;

  width: $size + px;
  height: $size + px;
}

@mixin triangle-right( $color, $size ) {
  @include ib;

  border: $size / 2 + px solid transparent;
  border-left-color: $color;
  border-right: 0;

  width: $size + px;
  height: $size + px;
}

@mixin container {
  display: block;
  margin: 0 auto;

  max-width: 1170px;
  min-width: 320px;
}

@mixin full {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@mixin full-abs {
  position: absolute;
  @include full;
}

@mixin full-fix {
  position: fixed;
  @include full;
}

@mixin full-rel {
  position: relative;

  width: 100%;
  height: 100%;
}

@mixin va( $is_block: 0 ) {
  @if $is_block == 0 {
    display: inline-flex;
    vertical-align: top;
  } @else {
    display: flex;
  }
  flex-direction: column;
  justify-content: center;
}

@mixin va-tb {
  position: relative;

  display: table;
  vertical-align: middle;

  width: 100%;
}

@mixin va-tc {
  position: relative;

  display: table-cell;
  vertical-align: middle;
}

@mixin va-abs {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin flc {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@mixin fl-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

@mixin hide-play() {
  &::-webkit-media-controls-panel {
    display: none!important;
    -webkit-appearance: none;
  }

  &::--webkit-media-controls-play-button {
    display: none!important;
    -webkit-appearance: none;
  }

  &::-webkit-media-controls-start-playback-button {
    display: none!important;
    -webkit-appearance: none;
  }
}

@mixin ios-border-radius-null {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0;
}

@mixin sprite($animationName, $frames, $stepWidth) {
  @keyframes #{$animationName} {
    0% {
      background-position: 0px 0;
    }

    100% {
      background-position: -#{$frames * $stepWidth}px 0;
    }
  }
}

@mixin float-clear {
  &:after {
    content: '';

    clear: both;

    display: block;
    margin-top: -1px;

    width: 100%;
    height: 1px;
  }
}

@mixin reset-button() {
  @include ib;

  padding: 0;
  margin: 0;
  border: none;

  font-family: inherit;
  font-size: inherit;

  background: transparent;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

@mixin icon($w, $h, $image) {
  width: $w;
  height: $h;

  background: transparent i($image) center center no-repeat;
  background-size: 100% auto;
};

@mixin icon-local($image) {
  background: transparent url($image) center center no-repeat;
  background-size: contain;
};

@mixin full-reset($w: auto, $h: auto) {
  position: static;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  transform: none;

  width: $w;
  height: $h;
}

@mixin link-reset($is-use-hover: 1) {
  color: inherit;
  text-decoration: none;
  transition: all $time-fast;
}

@mixin prop($width: 1, $height: 1) {
  &:before {
    content: '';
    display: block;
    padding-top: percentage($height / $width);
  }
}

@mixin size($width, $height: 0, $relative: $fz-root) {
  @if $height == 0 {
    $height: $width
  }
  width: rem($width, $relative);
  height: rem($height, $relative);
}

@mixin list-el() {
  &:not(:last-child) {
    @content;
  }
}

@mixin button() {
  @include reset-button;
  @include flc;

  display: flex;

  font-size: rem(20);
  text-transform: uppercase;
  font-weight: 400;

  background: rgba(255, 255, 255, 0.15);
  border: 1px solid $c-white;
  backdrop-filter: blur(rem(4));
  color: $c-white;
  transition: all $time-fast;

  &:hover {
    background: rgba(255, 255, 255, 0.25);
  }

  &:disabled {
    color: rgba($c-white, 0.5);
    border-color: rgba($c-white, 0.5);
    cursor: default;
    pointer-events: none;
  }

  @include mmax(768) {
    font-size: rem(16);
  }
}

@mixin menu-animate-item-hide {
  opacity: 0;
  transform: translate(0, em(50));
}

@mixin menu-animate-item-show {
  opacity: 1;
  transform: translate(0, 0);
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: $z-alpha-over + 1;

  height: $vh100;

  background: $c-black;
  opacity: 0;
  pointer-events: none;
  transition: all 700ms;

  &--open {
    opacity: 1;
    pointer-events: initial;
  }

  @include mmax(769) {
    z-index: $z-alpha-over;
  }

  &__bg {
    @include full-abs;
  }

  &__bg-img {
    @include full-rel;
    @include full-abs;
    object-fit: cover;

    opacity: 0.06;

    @include mmax(768) {
      opacity: 1;
    }
  }

  &__cont {
    @include full-rel;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: rem(48) rem(100) rem(80);

    @include mmax(768) {
      overflow-x: hidden;
      overflow-y: scroll;
      @include full-rel;

      padding: rem($header-m-h-big) 0 0 0;
      display: block;

      &:after {
        content: '';

        display: block;
        padding: 0 0 rem(26 + 160 - 40);
      }
    }
  }

  &__logo {
    @include menu-animate-item-hide;

    @include mmax(768) {
      display: none;
    }

    img {
      width: rem(40);
    }
  }

  &--open &__logo {
    @include menu-animate-item-show;
    transition: all 500ms 300ms;
  }

  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: rem(50);
    margin: auto;
    
    width: 100%;
    height: 100%;
    max-height: rem(750);

    text-align: center;

    @include mmax(768) {
      padding: rem(8) rem(50) 0;
      align-items: flex-start;
      justify-content: flex-start;

      height: auto;
      max-height: inherit;

      text-align: left;
    }
  }

  &__section {
    @include menu-animate-item-hide;

    @include mmax(768) {
      transform: translate(0, em(20));

      @include list-el {
        margin-bottom: rem(40);
      }
    }
  }

  @for $i from 1 through 4 {
    &--open &__section:nth-child(#{$i}) {
      @include menu-animate-item-show;
      transition: all 500ms #{300 + 100 * $i}ms;

      @include mmax(768) {
        transition: all 500ms #{200 * $i}ms;
      }
    }
  }

  &__section-heading {
    font-size: rem(40);
    margin-bottom: rem(10);

    @include mmax(768) {
      font-size: rem(20);
      margin-bottom: rem(18);
    }

    a {
      color: $c-white;
    }
  }

  &__section-items {
    @include flc;

    @include mmax(768) {
      display: block;
    }
  }

  &__section-item {
    font-size: rem(20);

    @include list-el {
      margin-right: rem(80);
    }

    @include mmax(768) {
      font-size: rem(14);

      @include list-el {
        margin-bottom: rem(20);
      }
    }
  }


  &__link {
    color: $c-link-def;
    transition: all $time-fast;

    &:hover,
    &.active {
      color: $c-white;
    }
  }

  &__additional {
    position: absolute;
    bottom: rem(80);
    left: rem(100);
    right: rem(100);

    pointer-events: none;

    @include menu-animate-item-hide;

    @include mmax(768) {
      position: static;
      transform: none !important;
      opacity: 1;
    }
  }

  &--open &__additional {
    @include menu-animate-item-show;
    transition: all 500ms #{300 + 500}ms;
  }

  &__address {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-bottom: rem(46);

    pointer-events: none;

    @include mmax(768) {
      display: block;
      margin-bottom: 0;
      margin-top: rem(36);

      text-align: center;
    }
  }

  &__address-el {
    @include link-reset;
    font-size: rem(18);

    @include mmax(768) {
      display: block;
      margin-top: rem(10);

      font-size: rem(12);
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &--open &__address-el {
    pointer-events: all;
  }

  &__additional-main {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    pointer-events: none;

    @include mmax(768) {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;

      flex-direction: column;
      justify-content: center;
      padding-bottom: rem(20);

      height: rem(160);

      background: linear-gradient(180deg, rgba(25, 25, 27, 0) 0%, #19191B 28.93%, #19191B 100%)
    }
  }

  &__socials {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    @include mmax(768) {
      margin-top: auto;
    }
  }

  &--open &__socials {
    pointer-events: all;
  }

  &__social {
    @include flc;
    margin-right: rem(24);
    @include size(48);

    border-radius: 50%;
    background: rgba(194, 195, 197, 0.1);
    backdrop-filter: blur(rem(20));

    @include mmax(768) {
      @include size(38);
      margin: 0 rem(10);
    }

    &:after {
      content: '';

      display: block;
    }

    &--insta:after {
      @include size(26);
      background: transparent url("./i/insta.svg") center center no-repeat;
      background-size: 100% auto;

      @include mmax(768) {
        @include size(21);
      }
    }

    &--fb:after {
      @include size(12, 26);
      background: transparent url("./i/fb.svg") center center no-repeat;
      background-size: 100% auto;

      @include mmax(768) {
        @include size(9, 20);
      }
    }
  }

  &__lang-switcher {
    @include mmax(768) {
      margin-top: rem(8);
    }
  }

  &--open &__lang-switcher {
    pointer-events: all;
  }

  &__lang-switcher-el {
    margin-left: rem(46);

    color: $c-link-def;
    transition: all $time-fast;

    &:hover,
    &.active {
      color: $c-white;
    }

    @include mmax(786) {
      margin: 0 rem(25);

      font-size: rem(16);
    }
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;

    @include mmax(768) {
      display: none;
    }
  }
}
