@function i($name) {
  @return url('../i/#{$name}');
}

@function rem($root, $current: $fz-root) {
  @return ($current / $root) * 1rem;
}

@function svg($name) {
  @return url('../i/#{$name}.svg');
}

// Converts pixels to em
@function em($src-val-pixels, $relative-val: $fz-root, $root-font-size-px: $fz-root) {
  // benefits of this function:
  // * no need type px
  // * can do em(270/2), em(270*2), em(270/2+5), em(270/2-5)

  // usage examples:
  // em(16)
  // em(24, 16)

  // for simple transition from px units to non px units
  @if str-length(unit($src-val-pixels)) == 0 {
    $src-val-pixels: $src-val-pixels * 1px;
  }
  @if str-length(unit($relative-val)) == 0 {
    $relative-val: $relative-val * 1px;
  }

  @if unit($relative-val) == 'em' {
    $relative-val: $relative-val / ($relative-val * 0 + 1); // strip units
    @return #{($src-val-pixels / ($root-font-size-px * $relative-val)) * 1em};
  } @else if unit($relative-val) == 'px' {
    @return #{$src-val-pixels / $relative-val * 1em};
  }
}

// Converts pixels to rem
@function rem($src-val-pixels, $relative-val: $fz-root, $root-font-size-px: $fz-root) {
  // benefits of this function:
  // * no need type px
  // * can do em(270/2), em(270*2), em(270/2+5), em(270/2-5)

  // usage examples:
  // em(16)
  // em(24, 16)

  // for simple transition from px units to non px units
  @if str-length(unit($src-val-pixels)) == 0 {
    $src-val-pixels: $src-val-pixels * 1px;
  }
  @if str-length(unit($relative-val)) == 0 {
    $relative-val: $relative-val * 1px;
  }

  @if unit($relative-val) == 'rem' {
    $relative-val: $relative-val / ($relative-val * 0 + 1); // strip units
    @return #{($src-val-pixels / ($root-font-size-px * $relative-val)) * 1rem};
  } @else if unit($relative-val) == 'px' {
    @return #{$src-val-pixels / $relative-val * 1rem};
  }
}

// Fonts
$fz-root: 20px;
$fz-root-m: 16px;
$ff-main: 'Montserrat', sans-serif;
$letter-spacing: (0.3 / 20) + em;

// Colors
$c-white: #ffffff;
$c-black: #19191B;
$c-link-def: #C2C3C5;
$c-link-def-darken: #848484;
$c-text: #ffffff;

// Timings
$time-fast: .3s;
$time-normal: .5s;

$timing-functions: (
  'easeInOutCubic': cubic-bezier(0.645, 0.045, 0.355, 1)
);

$header-h: 102px;
$header-m-h: 45px;
$header-m-h-big: 74px;
$padding-hor: 60px;
$padding-hor-m: 16px;

$vh-key: --vh !default;
$vh-key-init: --vh-init !default;

$vh1: var(#{$vh-key}, 1vh) !default;
$vh1init: var(#{$vh-key-init}, 1vh) !default;
$vh100: calc(var(#{$vh-key}, 1vh) * 100) !default;
$vh100init: calc(var(#{$vh-key-init}, 1vh) * 100) !default;

$m-panel-h: 70px;

// to kill
$sidebar-w: 0px;

// z-index

$z-over: 10;
$z-alpha-over: 1000;
$z-mega-over: 10000;

@mixin ib {
  display: inline-block;
  vertical-align: top;
}

@mixin it {
  display: inline-table;
  vertical-align: middle;
}

@mixin link {
  &,
  &:hover,
  &:active,
  &:visited,
  &:focus {
    @content;
  }
}

@mixin link-h {
  &:hover,
  &:active,
  &:focus {
    @content
  }
}

@mixin link-v {
  &,
  &:visited {
    @content;
  }
}

@mixin mmax( $width, $height: 0 ) {
  $w: $width + px;
  $h: $height + px;

  @if $height == 0 {
    @media all and (max-width: $w) {
      @content;
    }
  } @else {
    @media all and (max-width: $w) and (max-height: $h) {
      @content;
    }
  }
}

@mixin mmin( $width, $height: 0 ) {
  $w: $width + px;
  $h: $height + px;

  @if $height == 0 {
    @media all and (min-width: $w) {
      @content;
    }
  } @else {
    @media all and (min-width: $w) and (min-height: $h) {
      @content;
    }
  }
}

@mixin mmax-h( $height ) {
  $h: $height + px;

  @media all and (max-height: $h) {
    @content;
  }
}

@mixin mmax-vert( $width, $height: 1024 ) {
  $w: $width + px;
  $h: $height + px;
  @media all and (max-width: $w) and (max-height: $h) {
    @content;
  }
}

@mixin mmax-landscape( $width, $height: 0 ) {
  $w: $width + px;
  $h: $height + px;

  @if $h = 0 {
    @media all and (max-width: $w) and (orientation: landscape){
      @content;
    }
  } @else {
    @media all and (max-width: $w) and (max-height: $h) and (orientation: landscape) {
      @content;
    }
  }
}

@mixin adapt-img {
  max-width: 100%;
  height: auto;
}

@mixin placeholder {
  &::-webkit-input-placeholder {@content;}
  &:-ms-input-placeholder {@content;}
  &::-ms-input-placeholder {@content;}
  &:-moz-placeholder {@content;}
  &::-moz-placeholder {@content;}
}

@mixin placeholder-a-left {
  @include placeholder {
    @content;
    opacity: 1;
    text-indent: 0px;
    transition: .3s all;
  }

  &:focus {
    @include placeholder {
      opacity: 0;
      text-indent: -50px;
    }
  }
}

@mixin placeholder-a-fade {
  @include placeholder {
    @content;
    opacity: 1;
    transition: .3s all;
  }

  &:focus {
    @include placeholder {
      opacity: 0;
    }
  }
}

@mixin no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

@mixin select {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
}


@mixin triangle( $color, $size ) {
  @include ib;

  border: $size / 2 + px solid transparent;
  border-top-color: $color;
  border-bottom: 0;

  width: $size + px;
  height: $size + px;
}

@mixin triangle-rev( $color, $size ) {
  @include ib;

  border: $size / 2 + px solid transparent;
  border-bottom-color: $color;
  border-top: 0;

  width: $size + px;
  height: $size + px;
}

@mixin triangle-right( $color, $size ) {
  @include ib;

  border: $size / 2 + px solid transparent;
  border-left-color: $color;
  border-right: 0;

  width: $size + px;
  height: $size + px;
}

@mixin container {
  display: block;
  margin: 0 auto;

  max-width: 1170px;
  min-width: 320px;
}

@mixin full {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@mixin full-abs {
  position: absolute;
  @include full;
}

@mixin full-fix {
  position: fixed;
  @include full;
}

@mixin full-rel {
  position: relative;

  width: 100%;
  height: 100%;
}

@mixin va( $is_block: 0 ) {
  @if $is_block == 0 {
    display: inline-flex;
    vertical-align: top;
  } @else {
    display: flex;
  }
  flex-direction: column;
  justify-content: center;
}

@mixin va-tb {
  position: relative;

  display: table;
  vertical-align: middle;

  width: 100%;
}

@mixin va-tc {
  position: relative;

  display: table-cell;
  vertical-align: middle;
}

@mixin va-abs {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin flc {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@mixin fl-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

@mixin hide-play() {
  &::-webkit-media-controls-panel {
    display: none!important;
    -webkit-appearance: none;
  }

  &::--webkit-media-controls-play-button {
    display: none!important;
    -webkit-appearance: none;
  }

  &::-webkit-media-controls-start-playback-button {
    display: none!important;
    -webkit-appearance: none;
  }
}

@mixin ios-border-radius-null {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0;
}

@mixin sprite($animationName, $frames, $stepWidth) {
  @keyframes #{$animationName} {
    0% {
      background-position: 0px 0;
    }

    100% {
      background-position: -#{$frames * $stepWidth}px 0;
    }
  }
}

@mixin float-clear {
  &:after {
    content: '';

    clear: both;

    display: block;
    margin-top: -1px;

    width: 100%;
    height: 1px;
  }
}

@mixin reset-button() {
  @include ib;

  padding: 0;
  margin: 0;
  border: none;

  font-family: inherit;
  font-size: inherit;

  background: transparent;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

@mixin icon($w, $h, $image) {
  width: $w;
  height: $h;

  background: transparent i($image) center center no-repeat;
  background-size: 100% auto;
};

@mixin icon-local($image) {
  background: transparent url($image) center center no-repeat;
  background-size: contain;
};

@mixin full-reset($w: auto, $h: auto) {
  position: static;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  transform: none;

  width: $w;
  height: $h;
}

@mixin link-reset($is-use-hover: 1) {
  color: inherit;
  text-decoration: none;
  transition: all $time-fast;
}

@mixin prop($width: 1, $height: 1) {
  &:before {
    content: '';
    display: block;
    padding-top: percentage($height / $width);
  }
}

@mixin size($width, $height: 0, $relative: $fz-root) {
  @if $height == 0 {
    $height: $width
  }
  width: rem($width, $relative);
  height: rem($height, $relative);
}

@mixin list-el() {
  &:not(:last-child) {
    @content;
  }
}

@mixin button() {
  @include reset-button;
  @include flc;

  display: flex;

  font-size: rem(20);
  text-transform: uppercase;
  font-weight: 400;

  background: rgba(255, 255, 255, 0.15);
  border: 1px solid $c-white;
  backdrop-filter: blur(rem(4));
  color: $c-white;
  transition: all $time-fast;

  &:hover {
    background: rgba(255, 255, 255, 0.25);
  }

  &:disabled {
    color: rgba($c-white, 0.5);
    border-color: rgba($c-white, 0.5);
    cursor: default;
    pointer-events: none;
  }

  @include mmax(768) {
    font-size: rem(16);
  }
}

.chars {
  overflow: hidden;

  &__cont {
    margin-top: rem(38);

    @include mmax(786) {
      margin-top: rem(30);
    }
  }
}

.chars-section {
  &--type-2 {
    margin-top: rem(233);
  }

  &--type-3 {
    margin-top: rem(232 + 180);
  }

  &--type-4 {
    margin-top: rem(177 + 198);
  }

  &--type-5 {
    margin-top: rem(-5);
  }

  &--type-7 {
    margin-top: rem(177 + 198 - 30);
  }

  &--type-8 {
    margin-top: rem(248 - 30);
  }

  &--9 {
    margin-top: rem(35);
  }

  &--type-10 {
    margin-top: rem(402);
  }

  &--11 {
    margin-top: rem(177 + 198 - 30 + 55);
  }

  &--12 {
    margin-top: rem(89);
    padding-bottom: rem(162 - 108);
  }

  @include mmax(768) {
    margin: 0;
  }

  &__cont {

  }

  &__wrap {

  }

  &__icon {
    line-height: 0;
  }

  &--type-1 &__icon {
    position: absolute;
    top: 0;
    left: rem(802 - 262);
  }

  &--type-2 &__icon {
    position: absolute;
    bottom: rem(18);
    left: 100%;
  }

  &--type-3 &__icon {
    position: absolute;
    bottom: rem(18);
    right: 100%;
  }

  &--type-4 &__icon {
    position: absolute;
    bottom: rem(18);
    left: 100%;
  }

  &--type-5 &__icon {
    position: absolute;
    top: 0;
    left: rem(1010 + 55);
  }

  &--type-6 &__icon {
    margin-bottom: rem(42);
  }

  &--type-7 &__icon {
    position: absolute;
    bottom: rem(18);
    left: 100%;
  }

  &--type-8 &__icon {
    position: absolute;
    bottom: rem(18);
    right: 100%;
  }

  &--9 &__icon {
    position: static;
    margin-bottom: rem(42);
  }

  &--type-10 &__icon {
    position: absolute;
    bottom: rem(18);
    right: 100%;
  }

  &__icon-wrap {
    @include size(262);
    @include flc;

    border: 1px solid rgba(255, 255, 255, 0.2);

    @include mmax(768) {
      @include size(104);

      margin: rem(28) auto 0;
    }
  }

  &__icon-img {
    @include size(151);
    object-fit: contain;

    @include mmax(768) {
      @include size(60);
    }
  }

  &__text {
    position: relative;
  }

  &--type-1 &__text {
    width: rem(1260);
    margin-left: rem(552);
  }

  &--type-2 &__text {
    padding-right: rem(15);
    margin-left: rem(542);
    width: rem(756);
  }

  &--type-3 &__text {
    margin-left: rem(536 - 89);
    margin-bottom: rem(-320);
    padding-left: rem(89);
    width: rem(700 + 89);
  }

  &--type-4 &__text {
    padding-right: rem(15);
    margin-left: rem(542);
    margin-bottom: rem(-320 - 25);
    width: rem(688 + 90);
  }

  &--type-5 &__text {
    margin-left: rem(542);
    margin-bottom: rem(45);
    width: rem(688);
  }

  &--type-6 &__text {
    margin-left: rem(542);
    margin-top: rem(-60);
    margin-bottom: rem(45);
    width: rem(688);
  }

  &--type-7 &__text {
    padding-right: rem(30);
    margin-left: rem(542);
    margin-bottom: rem(-320 - 25 + 120);
    width: rem(688 + 90);
  }

  &--type-8 &__text {
    margin-left: rem(536 - 89);
    margin-bottom: rem(66 - 20);
    padding-left: rem(89);
    width: rem(700 + 89);
  }

  &--type-10 &__text {
    margin-left: rem(536 - 89);
    margin-bottom: rem(-200 + 12);
    padding-left: rem(89);
    width: rem(717 + 89);
  }

  &--11 &__text {
    margin-bottom: rem(-320 - 25 + 120 - 55);
  }

  &--12 &__text {
    margin-top: 0;
  }

  &__title {
    margin: 0;

    font-size: rem(40);
    font-weight: 300;
    line-height: 1;
    text-transform: uppercase;
  }

  &__text-row {
    margin-top: rem(16);

    font-size: rem(20);
    text-transform: uppercase;
    font-weight: 300;

    b {
      margin: 0 rem(3);
      font-size: rem(40);
      font-weight: inherit;
    }

    .alone {
      margin-right: rem(80);

      &:last-child {
        margin: 0;
      }
    }
  }

  &__media {
    position: relative;
  }

  &--type-1 &__media {
    margin-top: rem(42);
  }

  &--type-2 &__media {
    margin-top: rem(50);
  }

  &__media-images {
    @include flc;
    justify-content: space-between;
    align-items: flex-end;
  }

  &--type-1 &__media-images {
    .media-image:nth-child(2) {
      margin-bottom: rem(188);
    }
  }

  &--type-4 &__media-images {
    .media-image:nth-child(1),
    .media-image:nth-child(2) {
      margin-bottom: rem(303);
    }
  }

  &--type-5 &__media-images {
    .media-image:nth-child(1) {
      margin-bottom: rem(229);
    }
  }

  &--type-7 &__media-images {
    .media-image:nth-child(1),
    .media-image:nth-child(2) {
      margin-bottom: rem(133);
    }
  }

  &--11 &__media-images {
    .media-image:nth-child(1),
    .media-image:nth-child(2) {
      margin-bottom: rem(133 + 16);
    }

    .media-image:nth-child(1) {
      height: rem(804) !important;
    }

    .media-image:nth-child(2) {
      height: rem(469) !important;
    }

    .media-image:nth-child(3) {
      height: rem(618) !important ;
    }
  }

  &--type-8 &__media-images {
    align-items: flex-start;
  }

  &__media-image {
    position: relative;
  }

  &__media-text {
    position: absolute;
    bottom: rem(100 - 40);
    left: rem(800 + 100);

    width: rem(740 + 130);
  }

  &:first-child .m-chars-section__cont {
    margin-top: 0;
  }
}

.m-chars-section {
  &__cont {
    margin-top: rem(54);
  }

  &__title {
    padding: 0 rem(30);
    margin: 0 0 rem(8);

    font-size: rem(14);
    font-weight: 300;
  }

  &__text-row {
    margin-top: rem(8);
    padding: 0 rem(30);

    font-size: rem(12);

    b {
      font-size: rem(14);
    }

    .alone {
      display: block;
      margin-bottom: rem(9);

      &:last-child {
        margin-bottom: 0;
      }
    }

    &--special {
      margin-top: rem(16);
      margin-bottom: rem(-10);

      .ts-animation {
        width: 100%;
      }

      .ts-animation__cont {
        width: 100%;
      }

      .ts-animation__cont > span {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;

        width: 100%;
      }

      .alone {
        width: 50%;
        margin-bottom: 0;

        b {
          display: block;
        }
      }
    }
  }

  &__icon {
    .chars-section__icon {
      position: static;
    }
  }

  &__media {
    margin-top: rem(35);
  }
}
