@function i($name) {
  @return url('../i/#{$name}');
}

@function rem($root, $current: $fz-root) {
  @return ($current / $root) * 1rem;
}

@function svg($name) {
  @return url('../i/#{$name}.svg');
}

// Converts pixels to em
@function em($src-val-pixels, $relative-val: $fz-root, $root-font-size-px: $fz-root) {
  // benefits of this function:
  // * no need type px
  // * can do em(270/2), em(270*2), em(270/2+5), em(270/2-5)

  // usage examples:
  // em(16)
  // em(24, 16)

  // for simple transition from px units to non px units
  @if str-length(unit($src-val-pixels)) == 0 {
    $src-val-pixels: $src-val-pixels * 1px;
  }
  @if str-length(unit($relative-val)) == 0 {
    $relative-val: $relative-val * 1px;
  }

  @if unit($relative-val) == 'em' {
    $relative-val: $relative-val / ($relative-val * 0 + 1); // strip units
    @return #{($src-val-pixels / ($root-font-size-px * $relative-val)) * 1em};
  } @else if unit($relative-val) == 'px' {
    @return #{$src-val-pixels / $relative-val * 1em};
  }
}

// Converts pixels to rem
@function rem($src-val-pixels, $relative-val: $fz-root, $root-font-size-px: $fz-root) {
  // benefits of this function:
  // * no need type px
  // * can do em(270/2), em(270*2), em(270/2+5), em(270/2-5)

  // usage examples:
  // em(16)
  // em(24, 16)

  // for simple transition from px units to non px units
  @if str-length(unit($src-val-pixels)) == 0 {
    $src-val-pixels: $src-val-pixels * 1px;
  }
  @if str-length(unit($relative-val)) == 0 {
    $relative-val: $relative-val * 1px;
  }

  @if unit($relative-val) == 'rem' {
    $relative-val: $relative-val / ($relative-val * 0 + 1); // strip units
    @return #{($src-val-pixels / ($root-font-size-px * $relative-val)) * 1rem};
  } @else if unit($relative-val) == 'px' {
    @return #{$src-val-pixels / $relative-val * 1rem};
  }
}

// Fonts
$fz-root: 20px;
$fz-root-m: 16px;
$ff-main: 'Montserrat', sans-serif;
$letter-spacing: (0.3 / 20) + em;

// Colors
$c-white: #ffffff;
$c-black: #19191B;
$c-link-def: #C2C3C5;
$c-link-def-darken: #848484;
$c-text: #ffffff;

// Timings
$time-fast: .3s;
$time-normal: .5s;

$timing-functions: (
  'easeInOutCubic': cubic-bezier(0.645, 0.045, 0.355, 1)
);

$header-h: 102px;
$header-m-h: 45px;
$header-m-h-big: 74px;
$padding-hor: 60px;
$padding-hor-m: 16px;

$vh-key: --vh !default;
$vh-key-init: --vh-init !default;

$vh1: var(#{$vh-key}, 1vh) !default;
$vh1init: var(#{$vh-key-init}, 1vh) !default;
$vh100: calc(var(#{$vh-key}, 1vh) * 100) !default;
$vh100init: calc(var(#{$vh-key-init}, 1vh) * 100) !default;

$m-panel-h: 70px;

// to kill
$sidebar-w: 0px;

// z-index

$z-over: 10;
$z-alpha-over: 1000;
$z-mega-over: 10000;

@mixin ib {
  display: inline-block;
  vertical-align: top;
}

@mixin it {
  display: inline-table;
  vertical-align: middle;
}

@mixin link {
  &,
  &:hover,
  &:active,
  &:visited,
  &:focus {
    @content;
  }
}

@mixin link-h {
  &:hover,
  &:active,
  &:focus {
    @content
  }
}

@mixin link-v {
  &,
  &:visited {
    @content;
  }
}

@mixin mmax( $width, $height: 0 ) {
  $w: $width + px;
  $h: $height + px;

  @if $height == 0 {
    @media all and (max-width: $w) {
      @content;
    }
  } @else {
    @media all and (max-width: $w) and (max-height: $h) {
      @content;
    }
  }
}

@mixin mmin( $width, $height: 0 ) {
  $w: $width + px;
  $h: $height + px;

  @if $height == 0 {
    @media all and (min-width: $w) {
      @content;
    }
  } @else {
    @media all and (min-width: $w) and (min-height: $h) {
      @content;
    }
  }
}

@mixin mmax-h( $height ) {
  $h: $height + px;

  @media all and (max-height: $h) {
    @content;
  }
}

@mixin mmax-vert( $width, $height: 1024 ) {
  $w: $width + px;
  $h: $height + px;
  @media all and (max-width: $w) and (max-height: $h) {
    @content;
  }
}

@mixin mmax-landscape( $width, $height: 0 ) {
  $w: $width + px;
  $h: $height + px;

  @if $h = 0 {
    @media all and (max-width: $w) and (orientation: landscape){
      @content;
    }
  } @else {
    @media all and (max-width: $w) and (max-height: $h) and (orientation: landscape) {
      @content;
    }
  }
}

@mixin adapt-img {
  max-width: 100%;
  height: auto;
}

@mixin placeholder {
  &::-webkit-input-placeholder {@content;}
  &:-ms-input-placeholder {@content;}
  &::-ms-input-placeholder {@content;}
  &:-moz-placeholder {@content;}
  &::-moz-placeholder {@content;}
}

@mixin placeholder-a-left {
  @include placeholder {
    @content;
    opacity: 1;
    text-indent: 0px;
    transition: .3s all;
  }

  &:focus {
    @include placeholder {
      opacity: 0;
      text-indent: -50px;
    }
  }
}

@mixin placeholder-a-fade {
  @include placeholder {
    @content;
    opacity: 1;
    transition: .3s all;
  }

  &:focus {
    @include placeholder {
      opacity: 0;
    }
  }
}

@mixin no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

@mixin select {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
}


@mixin triangle( $color, $size ) {
  @include ib;

  border: $size / 2 + px solid transparent;
  border-top-color: $color;
  border-bottom: 0;

  width: $size + px;
  height: $size + px;
}

@mixin triangle-rev( $color, $size ) {
  @include ib;

  border: $size / 2 + px solid transparent;
  border-bottom-color: $color;
  border-top: 0;

  width: $size + px;
  height: $size + px;
}

@mixin triangle-right( $color, $size ) {
  @include ib;

  border: $size / 2 + px solid transparent;
  border-left-color: $color;
  border-right: 0;

  width: $size + px;
  height: $size + px;
}

@mixin container {
  display: block;
  margin: 0 auto;

  max-width: 1170px;
  min-width: 320px;
}

@mixin full {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@mixin full-abs {
  position: absolute;
  @include full;
}

@mixin full-fix {
  position: fixed;
  @include full;
}

@mixin full-rel {
  position: relative;

  width: 100%;
  height: 100%;
}

@mixin va( $is_block: 0 ) {
  @if $is_block == 0 {
    display: inline-flex;
    vertical-align: top;
  } @else {
    display: flex;
  }
  flex-direction: column;
  justify-content: center;
}

@mixin va-tb {
  position: relative;

  display: table;
  vertical-align: middle;

  width: 100%;
}

@mixin va-tc {
  position: relative;

  display: table-cell;
  vertical-align: middle;
}

@mixin va-abs {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin flc {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@mixin fl-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

@mixin hide-play() {
  &::-webkit-media-controls-panel {
    display: none!important;
    -webkit-appearance: none;
  }

  &::--webkit-media-controls-play-button {
    display: none!important;
    -webkit-appearance: none;
  }

  &::-webkit-media-controls-start-playback-button {
    display: none!important;
    -webkit-appearance: none;
  }
}

@mixin ios-border-radius-null {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0;
}

@mixin sprite($animationName, $frames, $stepWidth) {
  @keyframes #{$animationName} {
    0% {
      background-position: 0px 0;
    }

    100% {
      background-position: -#{$frames * $stepWidth}px 0;
    }
  }
}

@mixin float-clear {
  &:after {
    content: '';

    clear: both;

    display: block;
    margin-top: -1px;

    width: 100%;
    height: 1px;
  }
}

@mixin reset-button() {
  @include ib;

  padding: 0;
  margin: 0;
  border: none;

  font-family: inherit;
  font-size: inherit;

  background: transparent;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

@mixin icon($w, $h, $image) {
  width: $w;
  height: $h;

  background: transparent i($image) center center no-repeat;
  background-size: 100% auto;
};

@mixin icon-local($image) {
  background: transparent url($image) center center no-repeat;
  background-size: contain;
};

@mixin full-reset($w: auto, $h: auto) {
  position: static;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  transform: none;

  width: $w;
  height: $h;
}

@mixin link-reset($is-use-hover: 1) {
  color: inherit;
  text-decoration: none;
  transition: all $time-fast;
}

@mixin prop($width: 1, $height: 1) {
  &:before {
    content: '';
    display: block;
    padding-top: percentage($height / $width);
  }
}

@mixin size($width, $height: 0, $relative: $fz-root) {
  @if $height == 0 {
    $height: $width
  }
  width: rem($width, $relative);
  height: rem($height, $relative);
}

@mixin list-el() {
  &:not(:last-child) {
    @content;
  }
}

@mixin button() {
  @include reset-button;
  @include flc;

  display: flex;

  font-size: rem(20);
  text-transform: uppercase;
  font-weight: 400;

  background: rgba(255, 255, 255, 0.15);
  border: 1px solid $c-white;
  backdrop-filter: blur(rem(4));
  color: $c-white;
  transition: all $time-fast;

  &:hover {
    background: rgba(255, 255, 255, 0.25);
  }

  &:disabled {
    color: rgba($c-white, 0.5);
    border-color: rgba($c-white, 0.5);
    cursor: default;
    pointer-events: none;
  }

  @include mmax(768) {
    font-size: rem(16);
  }
}

.placement {
  overflow: hidden;

  &__cont {
    @include mmax(768) {
      margin-bottom: rem(56);
    }
  }

  &__section {

  }

  &__text {
    position: relative;

    margin-bottom: rem(56);

    @include mmax(768) {
      padding: 0 rem(30);
      margin-bottom: rem(20);

      width: 100%;
    }
  }

  &__section--map &__text {
    margin-top: rem(26);
    padding: 0 rem(98) 0 rem(552);

    @include mmax(768) {
      padding: 0 rem(30);
    }
  }

  &__title {
    margin-bottom: rem(15);

    font-size: rem(40);
    font-weight: 300;

    @include mmax(768) {
      font-size: rem(14);
      margin-bottom: rem(6);
    }
  }

  &__text-row {
    margin-bottom: rem(27);

    @include mmax(768) {
      margin-bottom: rem(8);

      font-size: rem(12);
    }
  }

  &__map {
    margin-top: rem(50);

    @include mmax(768) {
      margin: 0;
    }
  }

  &__map-cont {
    position: relative;
    @include size(1515, 550);
    margin: 0 0 0 auto;

    @include mmax(768) {
      @include size(320, 517);
    }
  }

  &__m-map-cats {
    @include button;

    position: absolute;
    bottom: rem(13);
    left: rem(30);
    right: 0;

    width: calc(100vw - #{rem(60)});
    height: rem(45);
  }

  &__map-infra {
    padding: 0 rem(98) 0 rem(552);

    @include mmax(768) {
      position: fixed;
      top: $header-m-h;
      left: 0;
      right: 0;
      z-index: 1;

      padding: 0;

      height: calc(#{$vh100} - #{$header-m-h});
      background: $c-black;
      opacity: 0;
      pointer-events: none;
      transition: all $time-fast;

      &--m-open {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  &__icon {
    position: absolute;
    bottom: 0;
    left: 100%;
    transform: translate(rem(35), rem(-15));

    @include mmax(768) {
      position: initial;
      transform: none;
      @include flc;

      margin-bottom: rem(40);
      margin-top: rem(30);
    }
  }

  &__icon-wrap {
    @include size(262);
    @include flc;
    border: 1px solid rgba($c-white, 0.2);

    @include mmax(768) {
      @include size(104);
    }
  }

  &__icon-img {
    @include size(145);
    object-fit: contain;

    @include mmax(786) {
      @include size(60);
    }
  }

  &__media {

  }

  &__media-images {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__media-image {
    width: 100%;
  }

  &__section--1 {
    margin-top: rem(191);

    @include mmax(768) {
      margin-top: rem(30);
    }

    .placement {
      &__text {
        margin-left: rem(545);

        width: rem(718);

        @include mmax(768) {
          width: 100%;
          margin-left: initial;
        }
      }

      &__media-image {
        width: rem(1860);
      }
    }
  }

  &__section--2 {
    margin-top: rem(80);

    @include mmax(768) {
      margin-top: rem(30);
    }

    .placement {
      &__text {
        margin-left: auto;
        margin-right: rem(101);
        margin-bottom: rem(-192);

        width: rem(718);
        min-height: rem(170);

        @include mmax(768) {
          width: 100%;
          margin-left: initial;
          margin-bottom: rem(20);
        }
      }

      &__media-image:nth-child(1) {
        padding-bottom: rem(80);

        width: rem(1000);
      }

      &__media-image:nth-child(2) {
        width: rem(864);
      }
    }
  }

  &__section--3 {
    margin-top: rem(180);

    @include mmax(768) {
      margin-top: rem(30);
    }

    .placement {
      &__text {
        margin-left: rem(545);

        width: rem(718);

        @include mmax(768) {
          width: 100%;
          margin-left: initial;
        }
      }

      &__media-images {
        position: relative;
      }

      &__media-image:nth-child(1) {
        position: absolute;
        left: 0;
        bottom: rem(382 + 61);

        width: rem(450);
      }

      &__media-image:nth-child(2) {
        width: rem(450);
      }

      &__media-image:nth-child(3) {
        width: rem(1410);
      }
    }
  }


  &__section--4 {
    @include mmax(768) {
      margin-top: rem(30);
    }

    .placement {
      &__text {
        margin-top: rem(100);
        margin-left: rem(545);

        width: rem(718);

        @include mmax(768) {
          width: 100%;
          margin-left: initial;
          margin-top: 0;
        }
      }

      &__media-images {
        align-items: flex-start;
      }

      &__media-image:nth-child(1) {
        width: rem(998);
      }

      &__media-image:nth-child(2) {
        margin-right: auto;
        margin-left: rem(58);

        width: rem(799);
      }
    }
  }
}

.placement-infra {
  @include mmax(768) {
    @include full-abs;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__cont {
    margin-top: rem(34);

    @include mmax(768) {
      margin: 0;
      padding: rem(15) rem(30);
    }
  }

  &__list {
    @include flc;
    justify-content: flex-start;
    align-items: flex-start;

    @include mmax(768) {
      display: block;
    }
  }

  &__el {
    position: relative;

    @include reset-button;
    margin-right: rem(40);

    font-size: rem(18);
    text-transform: uppercase;
    font-weight: 300;

    color: $c-link-def-darken;
    transition: all $time-fast;

    @include mmax(768) {
      @include flc;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: rem(26);

      &:last-child {
        margin-bottom: 0;
      }

    }

    &:hover {
      color: $c-white;
    }

    &:after {
      content: '';

      position: absolute;
      top: 100%;
      left: 0;
      right: rem(-15);
      transform: translate(0, rem(6));

      height: 1px;

      background: $c-link-def;
      opacity: 0;
      pointer-events: none;
      transition: all $time-fast;

      @include mmax(768) {
        display: none;
      }
    }

    &--active {
      font-weight: 400;

      color: $c-white;

      &:after {
        opacity: 1;
      }
    }
  }

  &__icon {
    margin-right: rem(30);

    line-height: 0;

    svg {
      @include size(24);
      object-fit: contain;
    }
  }

  &__label {
    @include mmax(768) {
      font-size: rem(14);
    }
  }

  &__el--type-all &__label {
    font-size: rem(16);
  }

  &__close {
    @include button;
    @include size(45);

    position: absolute;
    bottom: rem(15);
    right: rem(15);

    &:after {
      content: '';
      display: block;

      @include size(18, 16);
      background: transparent url("../../../shared/images/close.svg") center center no-repeat;
      background-size: contain;
    }
  }
}
